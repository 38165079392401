
@font-face {
  font-family: 'Manrope';
  src: url('/public/fonts/Manrope-VariableFont_wght.ttf') format('truetype');
  /* Wiederhole diesen Block für jede Schriftart, die du verwenden möchtest */
}

body {
  margin: 0;
  font-family: 'Manrope-Light';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Manrope';
}
